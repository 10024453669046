'use client';
import { HEADER_HEIGHT } from '@/components/AppHeader';
import { DIALOGS } from '@/constants/dialogs';
import { API } from '@/api';

export const classes = {
  wrapper: 'w-full h-full',
  body: 'flex',
  content: 'flex-1 h-full overflow-auto',
};

export const styles = {
  body: { height: `calc(100vh - ${HEADER_HEIGHT})` },
};

export const SHORTCUT_KEYS = {
  '\\P': DIALOGS.PROJECT,
  '\\C': DIALOGS.ACCOUNTS,
} as const;

export function getShortcut(
  e: KeyboardEvent
): keyof typeof SHORTCUT_KEYS | null {
  switch (e.key?.toLowerCase()) {
    case 'p':
      return '\\P';
    case 'c':
      return '\\C';
    default:
      return null;
  }
}

export async function fetchPracticeData(accountID: number) {
  const [practicePreferences, practiceUsers] = await Promise.all([
    API.Practices.fetchPracticePreferences(accountID),
    API.Practices.fetchPracticeUsers(accountID, {
      filters: [],
      pagination: { page: 1, pageSize: 100 },
    }),
  ]);
  return { practicePreferences, practiceUsers: practiceUsers.results };
}
