import { theme } from '@/utils/theme';

export const classes = {
  button:
    'w-full px-4 py-4 my-2 rounded-xl flex items-center justify-start select-none leading-6 tracking-wide transition-colors cursor-pointer text-base text-left',
  active: 'font-semibold shadow-sidebar-button',
  inactive:
    'text-grey-7 bg-white font-normal hover:text-grey-8 hover:bg-grey-3',
  icon: 'flex justify-center items-center font-normal',
  iconOpen: 'mr-5',
};

export const styles = {
  icon: { transition: 'margin-right 0.15s' },
  active: (color?: string, bgColor?: string) => ({
    color: color || 'white',
    backgroundColor: bgColor || theme.palette['purple-2'].main,
  }),
  label: (isCollapsed?: boolean) => ({
    transition: 'opacity 0.15s',
    opacity: isCollapsed ? 0 : 1,
  }),
};
