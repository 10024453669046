import { forwardRef } from 'react';
import Dialog from '@mui/material/Dialog';
import Slide, { type SlideProps } from '@mui/material/Slide';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import { SHORTCUT_KEYS } from '@/app/dashboard/utils';
import { classes, SHORTCUT_OPTIONS } from './utils';

const Transition = forwardRef<any, SlideProps>((props, ref) => {
  return <Slide direction="down" ref={ref} {...props} />;
});

export type ShortcutsDialogProps = {
  onSelect: (shortcut: keyof typeof SHORTCUT_KEYS) => void;
  onClose: () => void;
};

export default function ShortcutsDialog({
  onSelect,
  onClose,
}: ShortcutsDialogProps) {
  const renderListItem = ({
    name,
    shortcut,
  }: (typeof SHORTCUT_OPTIONS)[number]) => {
    return (
      <ListItem key={name} disablePadding>
        <ListItemButton
          className={classes.listItemButton}
          onClick={() => onSelect(shortcut)}>
          <div className={classes.shortcut}>{shortcut}</div>
          <div className={classes.name}>{name}</div>
        </ListItemButton>
      </ListItem>
    );
  };

  return (
    <Dialog
      open
      keepMounted
      maxWidth={false}
      TransitionComponent={Transition}
      PaperProps={{ className: classes.paper }}
      onClose={onClose}>
      <div className={classes.listWrapper}>
        <List className={classes.list}>
          {SHORTCUT_OPTIONS.map(renderListItem)}
        </List>
      </div>
    </Dialog>
  );
}
