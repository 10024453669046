import { twMerge } from 'tailwind-merge';
import { usePracticePreferences } from '@/store/useStore';
import { useSidebarCollapsed } from '@/hooks/useSidebarCollapsed';
import SidebarButton from './SidebarButton';
import CasesIcon from '@/icons/CasesIcon';
import FileIcon from '@/icons/FileIcon';
import MessagesIcon from '@/icons/MessagesIcon';
import MenuIcon from '@/icons/MenuIcon';
import { classes, styles } from './utils';

export default function AppSidebar() {
  const preferences = usePracticePreferences();
  const [collapsed, toggleCollapsed] = useSidebarCollapsed();

  const sharedProps = {
    collapsed,
    color: preferences?.buttonTextColor?.value,
    bgColor: preferences?.buttonBgColor?.value,
  };

  const handleToggleSidebar = () => toggleCollapsed(!collapsed);

  return (
    <div
      style={styles.sidebar}
      className={twMerge(
        classes.sidebar,
        collapsed ? classes.collapsed : classes.open
      )}>
      <div>
        <SidebarButton
          {...sharedProps}
          href="/dashboard/projects/home"
          label="Projects"
          icon={<CasesIcon sx={styles.icon} />}
        />
        <SidebarButton
          {...sharedProps}
          href="/dashboard/conversations/home"
          label="Conversations"
          icon={<MessagesIcon sx={styles.icon} />}
        />
        <SidebarButton
          {...sharedProps}
          href="/dashboard/files/home"
          label="Files"
          icon={<FileIcon sx={styles.icon} />}
        />
      </div>
      <div className={classes.iconWrapper}>
        <MenuIcon
          className={classes.menuIcon}
          style={styles.menuIcon(collapsed)}
          onClick={handleToggleSidebar}
        />
      </div>
    </div>
  );
}
