import { API } from '@/api';
import { type FetchMatchingUnisonProjectsResponse } from '@witmetrics/api-client';

export type SearchResults = FetchMatchingUnisonProjectsResponse['results'];

export const classes = {
  paper: 'fixed top-8 rounded-2xl',
  wrapper: 'flex flex-col w-[90vw] max-w-[800px] min-w-[200px] min-h-0',
  inputBase: 'w-full text-2xl px-6 py-4 flex-0',
  resultsList: 'flex-0 max-h-[40vh] overflow-auto',
};

export async function fetchMatchingProjects(query: string) {
  const timestamp = Date.now();
  // NOTE: Ignoring pagination
  const { results } = await API.UnisonProjects.fetchMatchingUnisonProjects({
    search: query,
    fields: ['name', 'description'],
    filters: [],
    pagination: { page: 1, pageSize: 20 },
  });
  return { timestamp, unisonProjects: results };
}
