import { useAppState } from '@/providers/AppStateProvider';
import {
  ACCOUNTS,
  LOGIN,
  PROJECT,
  SEARCH,
  SHORTCUTS,
} from '@/constants/dialogs';
import AccountsDialog from '@/dialogs/AccountsDialog';
import LoginDialog from '@/dialogs/LoginDialog';
import SearchDialog from '@/dialogs/SearchDialog';
import ShortcutsDialog from '@/dialogs/ShortcutsDialog';
import ProjectDialog from '@/dialogs/ProjectDialog';
import { SHORTCUT_KEYS } from '@/app/dashboard/utils';

export type DialogsManagerProps = {
  onSelectShortcut: (shortcut: keyof typeof SHORTCUT_KEYS) => void;
};

export default function DialogsManager({
  onSelectShortcut,
}: DialogsManagerProps) {
  const { activeDialogs, onCloseDialog } = useAppState();

  return (
    <>
      {activeDialogs[PROJECT] && (
        <ProjectDialog onClose={() => onCloseDialog(PROJECT)} />
      )}
      {activeDialogs[SEARCH] && (
        <SearchDialog onClose={() => onCloseDialog(SEARCH)} />
      )}
      {activeDialogs[SHORTCUTS] && (
        <ShortcutsDialog
          onSelect={onSelectShortcut}
          onClose={() => onCloseDialog(SHORTCUTS)}
        />
      )}
      {activeDialogs[ACCOUNTS] && (
        <AccountsDialog onClose={() => onCloseDialog(ACCOUNTS)} />
      )}
      {activeDialogs[LOGIN] && (
        <LoginDialog onClose={() => onCloseDialog(LOGIN)} />
      )}
    </>
  );
}
